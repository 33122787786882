import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Features from 'components/Features'
import Examples from 'components/Examples'
import Contacts from 'components/Contacts'
import FeedbackButton from 'components/FeedbackButton'
import FAQ from 'components/FAQ'
import Img from 'gatsby-image'

const ParsingAvito = ({ images }) => {
	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		pictures[node.name] = node.childImageSharp.fluid
	})
	return (
		<Layout>
			<SEO
				title="Парсинг Авито (avito.ru). Заказать парсинг в Excel — «Парсик»"
				description="Закажите парсинг объявлений Авито в Excel с телефонами, ценами, описаниями, адресом. Любые разделы и количество"
				keywords="парсинг, заказ, база, excel, доска, объявление, авито, avito, телефоны, email"
			/>
			<Banner
				title="Парсинг авито"
				description="Телефоны миллионов клиентов в&nbsp;вашем кармане"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<FeedbackButton theme="light" size="lg">
					Заказать парсинг
				</FeedbackButton>
			</Banner>
			<Sector>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<p className="fs-h4">
								Ваши конкуренты уже пользуются парсингом авито и&nbsp;сейчас звонят{' '}
								<strong className="text-nowrap">вашим клиентам</strong>. Так почему не&nbsp;звоните&nbsp;вы?
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'coin',
								title: 'Это выгодно',
								description: 'Затраты на&nbsp;разработку парсера авито окупаются уже через пару дней',
							},
							{
								url: '/',
								icon: 'customers',
								title: 'Выход в&nbsp;массы',
								description: 'Парсинг авито дает возможность предлагать услуги сразу сотням тысяч людей',
							},
							{
								url: '/',
								icon: 'strategy',
								title: 'Анализ данных',
								description: 'Объявления удобно исследовать для получения самых выгодных предложений',
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Узнать стоимость
					</FeedbackButton>
				</div>
			</Sector>
			<Sector color="blue" title="Закажите парсинг авито" isGradient titleCentered>
				<div className="fs-h4 text-center">
					<p>
						Мы&nbsp;спарсим для вас любое количество объявлений в&nbsp;любых разделах. Скачанную информацию будем
						аккуратно складывать в&nbsp;Excel файлик и&nbsp;отдавать вам
					</p>
				</div>
			</Sector>
			<Sector>
				<Grid fluid className="mb-lg">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Пример данных для парсинга</h2>
							<p className="fs-h4">
								Любая информация на&nbsp;ваш выбор, например:
								<br />
								Номер объявления, название, номер телефона, время публикации, адрес, цена товара, контактное лицо,
								фотографии, характеристики, описание
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Img fluid={pictures['avito-screen']} alt="Пример парсинга объявления авито" />
				</div>
				<Grid fluid className="mb-lg">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<h2 className="mb">Пример результата парсинга</h2>
							<p className="fs-h4">
								Excel файл с&nbsp;данными объявлений авито для дальнейшего анализа и&nbsp;использования в&nbsp;вашей CRM
							</p>
						</Col>
					</Row>
				</Grid>
				<Img fluid={pictures['avito-excel']} alt="Excel файл с данными парсинга объявлений авито" />
			</Sector>
			<Examples />
			<Sector title="Вопросы и ответы">
				<div className="mb-lg">
					<FAQ
						isNumbers={true}
						items={[
							{
								title: 'Что такое парсинг авито?',
								description: (
									<>
										<p>
											<strong>Парсинг авито</strong>&nbsp;&mdash; это автоматический сбор данных с&nbsp;самой крупнейшей
											на&nbsp;сегодняшний день в&nbsp;России доски объявлений&nbsp;&mdash; &laquo;Avito&raquo;. Авито
											содержит более 35&nbsp;миллионов активных объявлений с&nbsp;номерами телефонов клиентов, поэтому
											данные, полученные с&nbsp;помощью парсинга имеют огромную ценность и&nbsp;большой спрос среди
											маркетологов.
										</p>
									</>
								),
							},
							{
								title: 'Какие данные можно парсить с&nbsp;авито?',
								description: (
									<>
										<p className="no-last">
											Мы&nbsp;парсим все необходимые публичные данные объявлений, которые авито предоставляет
											в&nbsp;бесплатном доступе:
										</p>
										<ol>
											<li>Номер телефона в&nbsp;текстовом виде</li>
											<li>Контактное лицо и&nbsp;тип продавца</li>
											<li>Название объявления</li>
											<li>Полное описание</li>
											<li>Категория и&nbsp;подкатегория</li>
											<li>Цена объявления</li>
											<li>Адрес, город, район, метро</li>
											<li>Дата публикации</li>
											<li>Количество просмотров</li>
											<li>Номер объявления</li>
											<li>Изображения в&nbsp;виде списка URL-адресов</li>
											<li>Координаты географической локации продавца</li>
											<li>Ссылка на&nbsp;объявление</li>
										</ol>
										<p>
											Мы&nbsp;также можем спарсить дополнительные данные с&nbsp;авито, если клиенту будет необходимо
										</p>
									</>
								),
							},
							{
								title: 'Сколько стоит парсинг объявлений авито?',
								description: (
									<>
										<p>
											Цена парсинга обычного сайта в&nbsp;среднем 5000&nbsp;рублей. Авито является сложным донором,
											наравне с&nbsp;Цианом и&nbsp;Яндекс Маркетом, поэтому цена парсера авито будет явно дороже.
											Но&nbsp;мы&nbsp;не&nbsp;накручиваем цену по&nbsp;количеству объявлений. Например, цена парсинга
											авито 1000 объявлений будет такой&nbsp;же, как цена за&nbsp;парсинг 1&nbsp;миллиона объявлений.
											Разница в&nbsp;том, что результат парсинга вы&nbsp;сможете получать не&nbsp;так быстро. Поэтому,
											мы&nbsp;предлагаем нашим клиентам, заинтересованным в&nbsp;парсинге огромных данных, более
											выгодное предложение, чем другие сервисы. Фактически, вы&nbsp;платите только за&nbsp;разработку
											и&nbsp;поддержку парсера.
										</p>
										<p>
											Чтобы узнать точную цену парсинга авито, напишите нам, какие объявления вы&nbsp;хотите спарсить
											и&nbsp;из&nbsp;каких разделов.
										</p>
									</>
								),
							},
							{
								title: 'Где использовать данные парсинга объявлений с&nbsp;авито?',
								description: (
									<>
										<p className="no-last">
											Данные с&nbsp;авито, полученные посредством парсинга, имеют очень большую полезность. Самыми
											ценными являются контакты владельцев объявлений: номера телефонов и&nbsp;адреса. Современные
											программы и&nbsp;сервисы позволяют найти применения этим данным, такие как:
										</p>
										<ol>
											<li>
												Массовые рассылки сообщений по&nbsp;номерам телефонов через SMS и&nbsp;мессенджеры Viber,
												WhatsApp
											</li>
											<li>
												Роботизированные холодные звонки с&nbsp;распознаванием голоса потенциального клиента
												и&nbsp;перенаправлением его на&nbsp;оператора
											</li>
											<li>Наполнение собственной доски объявлений данными авито для быстрого старта</li>
											<li>Фильтрация, сортировка и&nbsp;анализ объявлений конкурентов</li>
											<li>Выгрузка объявлений в&nbsp;CRM для дальнейшей работы</li>
										</ol>
									</>
								),
							},
							{
								title: 'Почему лучше парсить авито?',
								description: (
									<>
										<p>
											Авито имеет самую большую базу объявлений частных лиц и&nbsp;компаний и&nbsp;занимает первое место
											в&nbsp;России и&nbsp;второе во&nbsp;всем мире. Авито содержит востребованные среди населения
											категории объявлений: транспорт, недвижимость, работа, услуги, личные вещи, для дома и&nbsp;дачи
											и&nbsp;многие другие.
										</p>
										<p>
											Все эти направления каким-либо образом затрагивают многие направления в&nbsp;бизнесе, поэтому эта
											информация имеют большую ценность среди предпринимателей.{' '}
										</p>
										<p>
											Парсинг объявлений авито является лучшим способом для выхода на&nbsp;массовое предложение своих
											услуг.
										</p>
									</>
								),
							},
							{
								title: 'Кому полезен парсинг авито?',
								description: (
									<>
										<p>
											Данные парсинга авито особенно интересны маркетологам. Почему? Все просто. Объявления добавляют
											люди сами, а&nbsp;это значит, что они ищут решение своей проблемы, будь&nbsp;то продажа или
											покупка вещей, поиск работы или получение услуги. В&nbsp;наше время предложение на&nbsp;рынке
											гораздо превышает спрос и&nbsp;найти нового клиента с&nbsp;каждым днем становится все сложнее.
											А&nbsp;где искать клиентов, как не&nbsp;в&nbsp;том месте, где люди изначально готовы пойти
											на&nbsp;контакт? Отчет очевиден. Авито имеет огромные объемы данных, которые идеально подходят для
											этих целей.
										</p>
										<p className="no-last">Парсинг авито необходим:</p>
										<ol>
											<li>
												Агентам по&nbsp;недвижимости, которые вынуждены ежедневно мониторить объявления и&nbsp;искать
												лучшие предложения. Парсинг авито недвижимости решает эту проблему, освобождая от&nbsp;ручной,
												рутинной работы
											</li>
											<li>
												Инициативным бизнесменам, работа которых предусматривает регулярный поиск контактов
												потенциальных клиентов. Ручной поиск объявлений совершенно не&nbsp;эффективен по&nbsp;времени.
												На&nbsp;помощь приходит парсер авито, который предоставит номера телефонов и&nbsp;адреса
												клиентов в&nbsp;удобном виде
											</li>
											<li>
												Компаниям, деятельность которых связана с&nbsp;закупкой, розничной и&nbsp;оптовой продажей
												товаров. Данные объявлений авито нужны им&nbsp;для анализа спроса и&nbsp;предложения
												на&nbsp;рынке.
											</li>
											<li>Посредникам, которые анализируют данные авито в&nbsp;поиске самых выгодных объявлений</li>
											<li>Хэдхантерам для оперативного поиска сотрудников</li>
											<li>Владельцам досок объявлений для автоматического наполнения сайта объявлениями авито</li>
										</ol>
										<p>Это далеко не&nbsp;все сферы применения парсинга авито</p>
									</>
								),
							},
							{
								title: 'В&nbsp;каком формате я&nbsp;получу объявления авито?',
								description: (
									<>
										<p>
											Парсинг позволяет выгружать данные в&nbsp;любой удобный формат. По&nbsp;умолчанию
											мы&nbsp;предоставляем нашим клиентам Excel файл с&nbsp;данными парсинга. Он&nbsp;универсален
											и&nbsp;кроссплатформен. Его удобно просматривать, анализировать и&nbsp;использовать как для
											выгрузки на&nbsp;ваш сайт или в&nbsp;вашу CRM, так и&nbsp;для конвертации в&nbsp;другой удобный
											формат, например XML, JSON. Если вам необходима выгрузка объявлений сразу в&nbsp;другом формате,
											мы&nbsp;сделаем это.
										</p>
									</>
								),
							},
							{
								title: 'Сколько времени нужно на&nbsp;создание парсера авито?',
								description: (
									<>
										<p>
											Мы&nbsp;не&nbsp;первый раз парсим объявления с&nbsp;авито и&nbsp;имеем наработки, которые сможем
											использовать для разработки вашего парсера. Примерное время создания парсера&nbsp;&mdash; 5-7 дней
											с&nbsp;момента утверждения технического задания. Точные сроки сможем назвать после получения
											предварительного задания с&nbsp;описанием требований и&nbsp;пожеланий.
										</p>
									</>
								),
							},
							{
								title: 'Как долго парсятся объявления с&nbsp;авито?',
								description: (
									<>
										<p>
											Обычно клиенты заинтересованы в&nbsp;парсинге авито определенной категории и&nbsp;конкретного
											региона. Большие разделы авито мы&nbsp;успеваем сканировать в&nbsp;течение суток.
											Небольшие&nbsp;&mdash; несколько раз в&nbsp;сутки. В&nbsp;обоих случаях клиент каждый день
											получает Excel файл со&nbsp;свежими данными.
										</p>
									</>
								),
							},
							{
								title: 'Выгодно&nbsp;ли парсить авито?',
								description: (
									<>
										<p>
											Парсить авито, как и&nbsp;любые другие доски объявлений безусловно выгодно. Цена разработки
											парсера окупается уже через несколько дней.
										</p>
										<p>
											Представьте, агента недвижимости, который каждый день анализирует информацию о&nbsp;ценах
											и&nbsp;спросе на&nbsp;недвижимость, анализирует конкурентов, ищет новых клиентов, звонит
											и&nbsp;предлагает свои услуги людям. Сколько объявлений в&nbsp;день способен обработать человек?
											Может тысячу или две? Даже с&nbsp;наличием стимула к&nbsp;работе и&nbsp;большого опыта это
											не&nbsp;эффективно, так как процесс очень утомителен и&nbsp;занимает много времени.{' '}
										</p>
										<p>
											Парсинг позволяет автоматически предоставить данные миллионов объявлений авито, отфильтрованные
											и&nbsp;отсортированные по&nbsp;необходимым бизнесу критериям. Эти данные можно легко использовать
											для автоматического обзвона по&nbsp;номерам телефонов клиентов или рассылки коммерческого
											предложения. Один день работы парсера с&nbsp;лихвой способен заменить месячный ручной труд
											человека.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли парсить объявления авито выборочной категории или региона?',
								description: (
									<>
										<p>
											Если вы&nbsp;занимаетесь узко-направленной деятельностью или предоставляете услуги людям
											в&nbsp;конкретном регионе, то&nbsp;лучше конечно настроить парсинг авито выборочных данных. Так
											парсеру нужно будет извлечь меньше информации и&nbsp;он&nbsp;отсканирует сайт гораздо быстрее.
											В&nbsp;итоге вы&nbsp;чаще будете получать актуальный Excel файл со&nbsp;свежими объявлениями, чем
											при парсинге всего сайта.
										</p>
									</>
								),
							},
							{
								title: 'Как вы&nbsp;парсите фотографии объявлений авито?',
								description: (
									<>
										<p>
											Мы&nbsp;парсим ссылки на&nbsp;все полноразмерные изображения объявлений авито и&nbsp;складываем
											их&nbsp;в&nbsp;итоговый Excel файл. Вы&nbsp;можете использовать их&nbsp;для загрузки на&nbsp;ваш
											сайт или для просмотра на&nbsp;авито.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли изменить тексты объявлений авито при парсинге?',
								description: (
									<>
										<p>
											Да, тексты объявлений мы&nbsp;можем менять &laquo;на&nbsp;лету&raquo;. Для этого используется
											специальный синонимайзер, который заменяет слова синонимами, повышая уникальность текста
											с&nbsp;разной степенью вхождений.
										</p>
										<p>При необходимости мы&nbsp;можем производить замену любых данных в&nbsp;объявлениях.</p>
										<p>
											Обычно, такая задача ставится для стартапов, наполнения сайтов, схожих с&nbsp;авито или новых
											досок объявлений. Это особенно часто необходимо на&nbsp;начальных этапах для скорейшей индексации
											в&nbsp;поиске.
										</p>
									</>
								),
							},
							{
								title: 'Как авито защищается от&nbsp;парсинга?',
								description: (
									<>
										<p className="no-last">
											Авито имеет несколько способов защиты информации от&nbsp;парсинга, которые не&nbsp;так просто
											обойти. В&nbsp;частности:
										</p>
										<ol>
											<li>
												Распознавание активности работов парсеров по&nbsp;техническим признакам и&nbsp;блокировка выдачи
												информации специальным скриптом
											</li>
											<li>Запрет на&nbsp;массовое получение страниц в&nbsp;короткий промежуток времени</li>
											<li>Ограничение на&nbsp;доступ к&nbsp;информации авито по&nbsp;географическому расположению</li>
											<li>
												Защита контактных данных владельцев объявлений. Так, номер телефона скрыт от&nbsp;индексации
												поисковых систем и&nbsp;парсеров. Так&nbsp;же он&nbsp;недоступен в&nbsp;открытом текстовом
												формате и&nbsp;хранится в&nbsp;виде изображения с&nbsp;текстом, что не&nbsp;позволяет
												использовать его стандартными методами
											</li>
											<li>Недоступность к&nbsp;платной информации, такой как резюме соискателей</li>
										</ol>
									</>
								),
							},
							{
								title: 'Как вы&nbsp;парсите авито?',
								description: (
									<>
										<p>
											Авито довольно хорошо защищается от&nbsp;парсинга, но&nbsp;любую защиту можно обойти, если очень
											хочется. Как следствие этого, парсинг объявлений авито более требователен к&nbsp;системным
											ресурсам, чем парсинг обычного сайта.
										</p>
										<p>
											В&nbsp;процессе парсинга скачиваются и&nbsp;расшифровываются изображения с&nbsp;номером телефона
											владельца объявления. В&nbsp;дополнение используются прокси-сервера, замедляющие
											интернет-соединение. Это сказывается на&nbsp;скорости парсинга, но&nbsp;это не&nbsp;мешает нам
											сканировать сотни тысяч страниц ежедневно!
										</p>
										<p className="no-last">
											Защиту авито от&nbsp;парсинга мы&nbsp;успешно обходим с&nbsp;помощью наработанных временем
											и&nbsp;опытом технических инструментов:
										</p>
										<ol>
											<li>Хорошие российские прокси-серверы для массового, многопоточного парсинга</li>
											<li>
												Скрипт, загружающий изображение с&nbsp;номером телефона и&nbsp;автоматическое распознавание
												в&nbsp;текстовый формат
											</li>
											<li>
												Алгоритм, основной целью которого является симуляция и&nbsp;рандомизация пользовательской
												активности на&nbsp;площадке объявлений
											</li>
										</ol>
									</>
								),
							},
							{
								title: 'Как заказать парсинг авито?',
								description: (
									<>
										<p>
											Подумайте, какие разделы авито вам нужно парсить, какой регион вам подходит, как часто вам
											необходимо формировать Excel файл со&nbsp;свежими объявлениями авито. Напишите нам на&nbsp;почту,
											используя контактную форму на&nbsp;сайте или позвоните нам на&nbsp;контактный номер телефона.
											Мы&nbsp;обдумаем ваши пожелания, обсудим объем работ, утвердим с&nbsp;вами цену, сроки
											и&nbsp;приступим к&nbsp;работе.
										</p>
									</>
								),
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>
			<Contacts color="gray" />
		</Layout>
	)
}

export default ParsingAvito
